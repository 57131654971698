import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';

import './scss/styles.scss';
import { Layout } from 'layout';
import Img404 from 'icons/img-404.svg';

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <title>Not Found</title>
    </Helmet>
    <div className="not-found-page">
      <div className="not-found-page__frame">
        <div className="not-found-page__content-wrapper">
          <Img404 />
          <div className="not-found-page__content">
            <p className="not-found-page__text">
              Sorry, looks like the page you are trying to reach no longer
              exists.
            </p>
            <p className="not-found-page__text">
              Let&apos;s get you <Link to="/">home</Link>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
